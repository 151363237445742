import React from 'react';
import msgStyles from '../../styles/research/msg.module.css';

export default function Msg(props) {
    document.body.style.backgroundColor = '#303030';
    document.title = 'Pesquisa de Satisfação';
    return (
        <div className={msgStyles.container} >
            Obrigado pela sua avaliação!
        </div>
    );
}