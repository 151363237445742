import React from 'react';
import axios from 'axios';

import { Submit, Short, Long, Dropdown, Checkbox, Range, Phone, Date, Label, Rate } from './research-form-fields';
import { API_PATH } from '../../globals.js';
import PaginaNaoEncontrada from '../pagina-nao-encontrada.jsx';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import formStyles from '../../styles/research/form.module.css';

export default class ResearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rest: null, // pk do restaurante
            formFields: [], // perguntas do formulário
            invalidFields: {}, // campos obrigatórios não preenchidos
        }
        this.requiredFields = {}; // Campos obrigatórios
        
        this.fieldValues = {}; // Valores de todos os campos
        this.customerData = { // Dados do cliente
            name: null,
            phone: null,
        }

        // Formulário simples (não é necessário informar nome e telefone e, ao enviar a resposta, retorna para o próprio formulário)
        this.simples = false;

        this.reCaptchaToken = null;

        const params = new URLSearchParams(document.location.search);
        let url = window.location.href;
        this.verifyRest(url, params.get('token'));
    }

    // Verifica se a url é válida
    verifyRest(url, token) {
        const self = this;
        const urlArray = url.split('/');
        var rest;
        for (let i = 0; i < urlArray.length; i++) { // Busca pelo token do restaurante informado
            if (urlArray[i] === 'research') {
                let restTemp = urlArray[i+1];
                rest = restTemp.split('?')[0];
                break;
            }
        }

        axios.post(API_PATH + '/research/verifyrest', { restaurant:rest, token:token }) // Verifica se o token é válido
            .then(function(response) { 
                self.setFormFields(rest, token); 
                self.setState({ rest: token, restName: rest });
                self.simples = response.data.extra === 'simples';
            })
            .catch(function(error) { // Erro ao conectar com a API
                self.setState({ formFields: 'Não foi possível carregar o formulário' });
            });
        return rest;
    }

    setCustomerData(key, value) {
        this.customerData[key] = value;
    }

    setFieldValue(pk, value) {
        this.fieldValues[pk] = value;
    }

    // Busca e cria os campos customizáveis do formulário
    setFormFields(rest, token) {
        const self = this;
        axios.post(API_PATH + '/Research/getformfields', { restaurant:rest, token:token } )
        .then(function(response) {
            // Campos padrão
            const forms = self.simples ? [] : [
                <Short required={1} id={'name'} key={-2} label={'Nome'} onInput={(e) => {
                    self.setCustomerData('name', e.target.value);
                }}></Short>,
                <Phone required={1} id={'phone'} invalid={self.state.invalidFields['phone']} key={-1} label={'Telefone'} onInput={(e) => {
                    self.setCustomerData('phone', e.target.value);
                }}></Phone>,
            ];
            let invTemp = self.simples ? {} : {'name': false, 'phone': false}; // Lista temporária de campos inválidos
            
            // Campos customizáveis
            for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].tipo !== 'label') { // Campo de Label não possui valor
                    self.setFieldValue(response.data[i].pk, null);
                }
                invTemp[response.data[i].pk] = false; // Seta o valor do campo como falso na lista de campos inválidos
                if (response.data[i].obrigatorio) { // Verifica de o campo é obrigatório
                    self.requiredFields[response.data[i].pk] = true;
                } else {
                    self.requiredFields[response.data[i].pk] = false;
                }
                switch (response.data[i].tipo) { // Cria o elemento
                    case 'label':
                        forms.push(<Label required={response.data[i].obrigatorio} id={response.data[i].pk} key={i} label={response.data[i].label}/>);
                        break;
                    case 'date':
                        forms.push(<Date required={response.data[i].obrigatorio} id={response.data[i].pk} key={i} label={response.data[i].label} onInput={(e) => {
                            self.setFieldValue(response.data[i].pk, e.target.value);
                        }}/>);
                        break;
                    case 'short':
                        forms.push(<Short required={response.data[i].obrigatorio} id={response.data[i].pk} key={i} label={response.data[i].label} onInput={(e) => {
                            self.setFieldValue(response.data[i].pk, e.target.value);
                        }}/>);
                        break;
                    case 'long':
                        forms.push(<Long required={response.data[i].obrigatorio} id={response.data[i].pk} key={i} label={response.data[i].label} onInput={(e) => {
                            self.setFieldValue(response.data[i].pk, e.target.value);
                        }}/>);
                        break;
                    case 'dropdown':
                        forms.push(<Dropdown required={response.data[i].obrigatorio} id={response.data[i].pk} key={i} label={response.data[i].label} options={response.data[i].options} onInput={(e) => {
                            self.setFieldValue(response.data[i].pk, e.target.value);
                        }}/>);
                        break;
                    case 'checkbox':
                        forms.push(<Checkbox required={response.data[i].obrigatorio} class={response.data[i].pk} key={i} label={response.data[i].label} options={response.data[i].options} onInput={(pk, value) => {
                            self.setFieldValue(pk, value);
                        }}/>);
                        break;
                    case 'range':
                        var min;
                        var max;
                        (response.data[i].min) ? min = response.data[i].min : min = 1;
                        (response.data[i].max) ? max = response.data[i].max : max = min+4;
                        forms.push(<Range required={response.data[i].obrigatorio} id={response.data[i].pk} key={i} label={response.data[i].label} min={min} max={max} onInput={(e) => {
                            self.setFieldValue(response.data[i].pk, e.target.value);
                        }}/>);
                        break;
                    case 'rate':
                        forms.push(<Rate label={response.data[i].label} required={response.data[i].obrigatorio} id={response.data[i].pk} key={i} text={response.data[i].label} onInput={(e) => {
                            self.setFieldValue(response.data[i].pk, e.target.value);
                        }}/>);
                        break
                    default:
                        console.log('Tipo de campo não configurado: ' + response.data[i].tipo);
                        break;
                }
            }
            self.setState({ formFields: forms, invalidFields: invTemp });
        }).catch(function(error) { // Erro ao conectar com a API
            self.setState({ formFields: 'Não foi possível carregar o formulário' });
        });
    }

    // Envia resposta ao servidor
    send(btn) {
        // Verifica se todos os campos obrigatórios foram preenchidos
        var ver = false
        var invalidFields = {...this.state.invalidFields}
        for (let key in this.fieldValues) {
            if (!this.requiredFields[key]) continue;
            if ((this.fieldValues[key] === null) || (this.fieldValues[key] === '__null__') || (this.fieldValues[key] === '')) {
                ver = true;
                invalidFields[key] = true;
            } else {
                invalidFields[key] = false;
            }
        }
        for (let key in this.customerData) {
            if ((key === 'phone') && (!/^[0-9]{11}$/.test(this.customerData[key])) && this.customerData[key] != null && this.customerData[key] !== '') {
                ver = true;
                invalidFields[key] = true;
            }
        }
        this.setState({ invalidFields: invalidFields });
        if (ver) return;
        // Verifica se todos os campos obrigatórios foram preenchidos

        if (this.customerData['phone'] === null || this.customerData['phone'] === '') {
            this.customerData = {
                name: 'Anônimo',
                phone: '-',
            }
        }

        btn.setLoading()
        // Envia os dados para a API
        axios.post(API_PATH + '/research/send', {
            captchaToken: this.reCaptchaToken,
            restaurant: this.state.restName,
            token: this.state.rest,
            customer: this.customerData,
            answers: this.fieldValues,
        })
        .then((response) => {
            if (this.simples) {
                window.location.reload();
            } else {
                window.location.href = '/research/enviado'; // Redireciona para página de "Enviado"
            }
        })
        .catch((error) => {
            if (this.simples) {
                window.location.reload();
            } else {
                window.location.href = '/research/erro'; // Redireciona para página de "Erro"
            }
        })
    }
    
    // Aviso para preencher os campos obrigatórios
    getAviso() {
        var txt = [];
        for (let key in this.state.invalidFields) {
            if ((this.state.invalidFields[key]) && (key !== 'phone')) {
                txt.push(<div key={0}>Preencha todos os campos obrigatorios *</div>);
                break;
            }  
        }
        if (this.state.invalidFields['phone']) {
            txt.push(<div key={1}>Telefone inválido</div>);
        }
        return <div className={formStyles.warning}>{txt}</div>;
    }

    render() {
        if (this.state.formFields === false) {
            return (<PaginaNaoEncontrada />)
        }
        document.body.style.backgroundColor = '#303030';
        document.title = 'Pesquisa de Satisfação';
        const self = this;
        return (
            <div className={formStyles.formContainer}>  
                <div className={formStyles.logoContainer}>
                    <img src={"/assets/img/logos/logo-"+self.state.restName+".png"} alt="" style={{ maxHeight: 150 }} />
                </div>

                {!this.simples && <h3 className={formStyles.formTitle}>Pesquisa de Satisfação</h3>} 
                
                {this.state.formFields}

                <GoogleReCaptchaProvider reCaptchaKey='6Ley5JwpAAAAAHXEr6HOo3zMjkViYf8QRrr6jkz0'>
                    <GoogleReCaptcha onVerify={(token) => {
                        self.reCaptchaToken = token
                    }}/>
                </GoogleReCaptchaProvider>

                { this.getAviso() }
                <Submit label="Enviar" onClick={ (btn) => {
                    self.send(btn);
                } }/>
            </div>
        ); 
    }
}