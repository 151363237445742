import React from 'react';
import msgStyles from '../../styles/research/msg.module.css';

export default function Msg(props) {
    document.body.style.backgroundColor = '#303030';
    document.title = 'Erro!';
    return (
        <div className={msgStyles.container} style={{color:'#f03e3e'}} >
            Houve um erro ao enviar sua resposta
        </div>
    );
}