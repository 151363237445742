import React, { useState } from 'react';
import formStyles from '../../styles/research/form.module.css';
import '../../styles/index.css';

export function Label(props) {
    return (
        <div id={props.id} className={formStyles.formGroup}>
            <label style={{fontSize:'19px'}} className={formStyles.formLabel}> {props.label}</label>
        </div>
    );
}

export function Short(props) {
    return (
        <div id={props.id} className={formStyles.formGroup}>
            <label className={formStyles.formLabel}> {props.label} {props.required ? '*' : ''}</label>
            <input className={formStyles.formControl} type="text" name={props.id} onInput={props.onInput}/>
        </div>
    );
}

export function Phone(props) {

    return (
        <div id={props.id} className={formStyles.formGroup}>
            <label className={formStyles.formLabel}> {props.label}  {props.required ? '*' : ''}</label>
            <input className={formStyles.formControl} type="tel" placeholder="Ex: DDD9XXXXXXXX" pattern="[0-9]*" name={props.id} onInput={(e) => {
                props.onInput(e);
                if (!/^[0-9]{11}$/.test(e.target.value)) {
                    e.target.style.color = '#f03e3e'
                } else {
                    e.target.style.color = '#000000'
                }
            }}/>
        </div>
    );
}

export function Date(props) {
    return (
        <div id={props.id} className={formStyles.formGroup}>
            <label className={formStyles.formLabel}> {props.label} {props.required ? '*' : ''}</label>
            <input className={formStyles.formControl} type="date" name={props.id} onInput={props.onInput}/>
        </div>
    );
}

export function Long(props) {
    return (
        <div id={props.id} className={formStyles.formGroup}>
            <label className={formStyles.formLabel}> {props.label} {props.required ? '*' : ''}</label>
            <textarea className={formStyles.formControl} name={props.id} cols="30" rows="7" onInput={props.onInput}></textarea>
        </div>
    );
}

export class Dropdown extends React.Component {
    getOptions() {
        if (this.props.options === undefined) {
            return
        }
        
        var opts = [<option value={'__null__'} key={-1}> Selecione uma opção </option>];
        for (let i = 0; i < this.props.options.length; i++) {
            opts.push(<option value={this.props.options[i].opcao} key={i}> {this.props.options[i].opcao} </option>)
        }
        return opts;
    }

    render() {
        return (
            <div id={this.props.id} className={formStyles.formGroup}>
                <label className={formStyles.formLabel}> {this.props.label} {this.props.required ? '*' : ''}</label>
                <select className={formStyles.formControl} name={this.props.id} onInput={this.props.onInput}>
                    {this.getOptions()}
                </select>
            </div>
        );
    }
}

export class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.values = []
    }

    getOptions() {
        var self = this;
        if (this.props.options === undefined) {
            return
        }
        var opts = [];
        for (let i = 0; i < this.props.options.length; i++) {
            opts.push(
                <div className={formStyles.checkbox} key={`checkbox-${i}`} onInput={function(e) {
                    let index = self.values.indexOf(self.props.options[i].opcao);
                    if (index === -1) {
                        self.values.push(self.props.options[i].opcao);
                    } else {
                        self.values.splice(index, 1);
                    }
                    self.values.sort();
                    self.props.onInput(self.props.class, self.values);
                }}>
                    <input type="checkbox" name={this.props.id} id={this.props.id} value={this.props.options[i].opcao}/>
                    <label htmlFor={this.props.id}> {this.props.options[i].opcao} </label>  
                </div>
            )   
            self.props.onInput(self.props.class, self.values);     
        }
        return opts;
    }

    render() {
        return (
            <div id={this.props.id} className={formStyles.formGroup}>
                <label className={formStyles.formLabel}> {this.props.label} {this.props.required ? '*' : ''}</label>
                {this.getOptions()}
            </div>
        );
    }
}

export class Range extends React.Component {
    getOptions() {
        var min
        var max
        if (this.props.min === undefined) {
            min = 0;
        } else {
            min = this.props.min;
        }
        if (this.props.max === undefined) {
            max = 5;
        } else {
            max = this.props.max;
        }
        
        const opts = [];
        for (let i = min; i <= max; i++) {  
            opts.push(
                <div key={i} className={formStyles.checkbox} >
                    <input type="radio" name={this.props.id} value={i} onInput={this.props.onInput}/>
                    <label> {i} </label>  
                </div>
            );
        }
        return (
            <div className={formStyles.radioGroup}>
                {opts}
            </div>
        );
    }

    render() {
        return (
            <div id={this.props.id} className={formStyles.formGroup}>
                <label className={formStyles.formLabel}> {this.props.label} {this.props.required ? '*' : ''}</label>
                {this.getOptions()}
            </div>
        );
    }
}

export function Rate({ id, onInput, label, required }) {
    const [check, setCheck] = useState(null);

    const Opt = function({ id, value, checked, text, onInput }) {
        return (
            <div className={ checked ? formStyles.rate_checked : formStyles.rate }>
                <input id={`rate_${id}_${value}`} type="radio" name={ id } value={ value } onInput={ onInput }/>
                <label className={ formStyles.rate_label} htmlFor={`rate_${id}_${value}`}><strong>{ text }</strong></label>
            </div>
        );
    }

    const onInputWrapper = function(e) {
        onInput(e);
        setCheck(e.target.value);
    }

    const get_opts = function() {
        // Edite este obj para configurar as opções
        const opts = {
            'Ótimo': '😊 Ótimo',
            'Bom': '🙂 Bom',
            'Regular': '😐 Regular',
            'Ruim': '🙁 Ruim'
        }

        const response = [];
        for (const key in opts) {
            response.push(<Opt key={ key } id={ id } value={ key } checked={ check === key } text={ opts[key] } onInput={ onInputWrapper }/>)
        }
        return response;
    }

    return (
        <div id={id} className={formStyles.formGroup}>
            <label className={formStyles.rate_label_big}> {label}</label>
            <div id={ id }>{ get_opts() }</div>
        </div>
    );
}

export class Submit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {loading: false};
    }

    setLoading() {
        this.setState({loading: true});
    }

    render() {
        if (this.state.loading) {
            return (
                <div id={this.props.id} className={formStyles.formGroup}>
                    <button className={formStyles.submit} onClick={this.props.onClick}> <span className={'fa fa-spinner spin'}></span> </button>
                </div>
            );
        } else {
            return (
                <div id={this.props.id} className={formStyles.formGroup}>
                    <button className={formStyles.submit} onClick={() => {
                        this.props.onClick(this);
                    }}> 
                    { this.props.label }
                    </button>
                </div>
            );
        }
        
    }
}