import { Routes, Route } from 'react-router-dom';
import PaginaNaoEncontrada from './components/pagina-nao-encontrada.jsx';
import ResearchForm from './components/research/research-form.jsx';
import Msg from './components/research/research-msg.jsx';
import MsgErro from './components/research/research-msg-erro.jsx';
    
function MainRoutes() {
    return (
        <Routes> 
            <Route exact path="*" element={ <PaginaNaoEncontrada /> }/>
            <Route exact path="/research/:rest" element={ <ResearchForm /> }/>
            <Route exact path="/research/enviado" element={ <Msg /> }/>
            <Route exact path="/research/erro" element={ <MsgErro /> }/>

            
        </Routes>
    );
}

export default MainRoutes;